<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-items-center justify-start">
        <v-icon>mdi-file-document</v-icon>
        <h4 class="ml-3 mb-0">รายละเอียดปรับปรุงสต๊อก</h4>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="4">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="5">
              <span class="label-drudsandmedical-add-stock-incard">รหัสปรับปรุงสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7">
              <span>{{stockNo}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="5">
              <span class="label-drudsandmedical-add-stock-incard">ราคาทุน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7">
              <span>{{formatThaiBaht(stockCostPrice)}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="5">
              <span class="label-drudsandmedical-add-stock-incard">วันที่ปรับปรุงสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7">
              <span>{{moment(stockDate).add(543, 'year').format('DD/MM/YYYY')}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="5">
              <span class="label-drudsandmedical-add-stock-incard">ผู้ปรับปรุงสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7">
              <span>{{stockUpdateBy}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="5">
              <span class="label-drudsandmedical-add-stock-incard">สาเหตุในการปรับปรุงสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="7">
              <span>{{stockCause}}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="8">
          <v-data-table
            :loading="loaddata"
            loading-text="กำลังโหลด..."
            :headers="headers"
            :items="data"
          >
            <template v-slot:item.stock_name="{ item }">
              <div class="text-start">{{item.stock_name}}</div>
            </template>
            <template v-slot:item.stock_bestbefore="{ item }">
              <div class="text-center">{{moment(item.stock_bestbefore).add('year', 543).format('DD/MM/YYYY')}}</div>
            </template>
            <template v-slot:item.stock_costprice="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_costprice)}}</div>
            </template>
            <template v-slot:item.stock_price="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_price)}}</div>
            </template>
            <template v-slot:item.stock_amount="{ item }">
              <div class="text-end">{{formatThaiBaht(item.stock_amount)}}</div>
            </template>
            <template v-slot:item.stock_delete="{ item }">
              <v-btn
                icon
                @click="deleteLineList(item)"
              >
                <v-icon large>mdi-minus-circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="e6 = 1"
              >
                รีเซ็ต
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="d-flex flex-row mt-3">
        <v-spacer/>
        <v-btn
          :loading="loadingprint"
          :disabled="loadingprint"
          color="blue-grey"
          class="mt-3 white--text"
          @click="gotoprint"
        >
          <v-icon
            left
            dark
          >
            mdi-printer
          </v-icon>
          พิมพ์ใบปรับปรุงสต๊อก
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loaddata: false,
      loadingprint: false,
      stockId: '',
      stockNo: '',
      stockDate: moment(),
      stockCostPrice: 0,
      stockUpdateBy: '',
      stockCause: '',
      data: []
    }
  },
  created () {
    const self = this
    self.loaddata = true
    self.data = []
    var temp = []
    if (self.$route.params.stockItems._id) {
      self.stockId = self.$route.params.stockItems._id
      self.stockNo = self.$route.params.stockItems.stock_no
      self.stockDate = self.$route.params.stockItems.stock_date
      self.stockCostPrice = self.$route.params.stockItems.stock_costprice
      self.stockUpdateBy = self.$route.params.stockItems.stock_lastupdate_by
      self.stockCause = 'นับสต๊อกผิด'

      temp = [{
        _id: 0,
        stock_select_medical_id: '00000000',
        stock_lineno: 1,
        stock_name: 'ไหมจมูก POD',
        stock_stockcycle: 'LT00029',
        stock_bestbefore: moment(),
        stock_remain: 3,
        stock_costprice: 150,
        stock_price: 0,
        stock_actions: 'เพิ่ม',
        stock_amount: 2,
        stock_unit: 'เส้น',
        stock_total: 0
      }, {
        _id: 1,
        stock_select_medical_id: '00000001',
        stock_lineno: 2,
        stock_name: 'ไหมจมูก POD',
        stock_stockcycle: 'LT00028',
        stock_bestbefore: moment(),
        stock_remain: 3,
        stock_costprice: 150,
        stock_price: 0,
        stock_actions: 'ลด',
        stock_amount: 1,
        stock_unit: 'เส้น',
        stock_total: 0
      }, {
        _id: 2,
        stock_select_medical_id: '00000002',
        stock_lineno: 3,
        stock_name: 'ไหมจมูก POD',
        stock_stockcycle: 'LT00027',
        stock_bestbefore: moment(),
        stock_remain: 3,
        stock_costprice: 150,
        stock_price: 0,
        stock_actions: 'ลด',
        stock_amount: 1,
        stock_unit: 'เส้น',
        stock_total: 0
      }]
    }
    setTimeout(() => {
      self.data = temp
      self.loaddata = false
    }, 200)
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          sortable: false,
          value: 'stock_lineno'
        }, {
          text: 'รายการ',
          align: 'center',
          sortable: false,
          value: 'stock_name'
        }, {
          text: 'รอบสต๊อก',
          align: 'center',
          sortable: false,
          value: 'stock_stockcycle'
        }, {
          text: 'วันที่หมดอายุ',
          align: 'center',
          value: 'stock_bestbefore',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'stock_costprice',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'stock_price',
          sortable: false
        }, {
          text: 'เพิ่ม/ลด',
          align: 'center',
          value: 'stock_actions',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'stock_amount',
          sortable: false
        }, {
          text: 'หน่วย',
          align: 'center',
          value: 'stock_unit',
          sortable: false
        }
      ]
    }
  },
  methods: {
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    gotoprint () {
      const self = this
      self.loadingprint = true
      setTimeout(() => {
        self.loadingprint = false
      }, 200)
    }
  }
}
</script>

<style scoped>
@import '../../../styles/DrugsAndMedical_AddStock.css';
</style>
